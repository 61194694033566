import request from "@/utils/request";

export function getScheduleGlobal(isTeacher, type) {
  return request({
    method: "post",
    data: isTeacher ? { type: type } : null,
    url: isTeacher ? "schedule/get_employee" : "schedule/get_global"
  });
}

export function getGradeOrClass(isGetGrade, data) {
  return request({
    method: "post",
    data: data,
    url: isGetGrade ? "schedule/get_grade" : "schedule/get_class"
  });
}

export function updateScheduleGlobal(data, isTeacher) {
  return request({
    method: "post",
    data: data,
    url: isTeacher ? "schedule/set_employee" : "schedule/set_global"
  });
}

export function updateGradeOrClass(isGrade, data) {
  return request({
    method: "post",
    data: data,
    url: isGrade ? "schedule/set_grade" : "schedule/set_class"
  });
}

export function setScheduleBulk(data, isTeacher) {
  return request({
    method: "post",
    data: data,
    url: isTeacher ? "schedule/set_employee" : "schedule/set_global_bulk"
  });
}
