import request from "@/utils/request";

export function class_list(data) {
  return request({
    method: "post",
    url: "class/all_list",
    data: data
  });
}

export function data_tiers() {
  return request({
    method: "post",
    url: "class/grade_lists"
  });
}

export function post_master_class(data) {
  return request({
    method: "post",
    url: "class/create-class",
    data: data
  });
}

export function post_class_list(data) {
  return request({
    method: "post",
    url: "class/add_class_detail",
    data: data
  });
}

export function get_teacher() {
  return request({
    method: "GET",
    url: "class/get-teacher"
  });
}

export function delete_class(data) {
  return request({
    method: "POST",
    url: "class/delete-class",
    data: data
  });
}

export function update_class(data) {
  return request({
    method: "POST",
    url: "class/update-class",
    data: data
  });
}
