<template>
  <div>
    <v-row class="my-3">
      <v-spacer />
      <v-col cols="12" sm="3" :class="$vuetify.breakpoint.xsOnly && 'mb-2'">
        <v-btn
          class="gradient-primary"
          dark
          depressed
          @click="confirmUpdate = true"
        >
          <v-icon size="20" class="mr-2">mdi-content-save</v-icon>
          {{ $t("app.save") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="tableHeaders"
      :items="dataSchedule"
      :loading="loading"
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:item.day="{ item }">
        <span>{{ g_language == "en" ? item.eng : item.ind }}</span>
      </template>
      <template v-slot:item.lesson_hours="{ item }">
        <v-row>
          <v-col cols="12" sm="4">
            <div class="d-flex align-center my-3">
              <div class="pl-2">
                <TimePicker
                  :label="$t('set_time.table.time_off_attendance')"
                  :isDisabled="!item.isDayOff"
                  :initValue="
                    item.normal_in != null ? item.normal_in.substr(0, 5) : ''
                  "
                  outlined
                  dense
                  hide-details
                  @value="v => (item.normal_in = v)"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="d-flex align-center my-3">
              <div class="pl-2">
                <TimePicker
                  :label="$t('set_time.table.time_away_home')"
                  :isDisabled="!item.isDayOff"
                  :initValue="
                    item.normal_out != null ? item.normal_out.substr(0, 5) : ''
                  "
                  :min="item.normal_in"
                  outlined
                  dense
                  hide-details
                  @value="v => (item.normal_out = v)"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <v-switch
              :label="
                !item.isDayOff ? $t('set_time.day_off') : $t('set_time.day_in')
              "
              v-model="item.isDayOff"
              hide-details
              @change="isDayOff(item)"
            />
          </v-col>
        </v-row>
      </template>
      <!-- <template v-slot:item.action="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="
                !item.isDayOff ? false : !item.normal_in || !item.normal_out
              "
              class="mx-2 primary"
              dark
              icon
              v-on="on"
              small
              @click="isDialogUpdate(item)"
            >
              <v-icon size="20">mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("set_time.save") }}</span>
        </v-tooltip>
      </template> -->
    </v-data-table>
    <ModalConfirm
      :title="$t('set_time.enter_school_schedule')"
      :close="() => (this.confirmUpdate = false)"
      :isOpen="confirmUpdate"
      :save="postData"
      :loading="loadingConfirm"
    >
      <span
        v-html="
          $t('set_time.confirm_update_1', {
            typeAbsent:
              type === 'TEACHER'
                ? $t('set_time.for_teacher')
                : type === 'STAFF'
                ? $t('set_time.for_staff')
                : $t('set_time.for_student')
          })
        "
      />
    </ModalConfirm>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters } from "vuex";
import { class_list } from "@/api/admin/academic/classList";
import { getScheduleGlobal, setScheduleBulk } from "@/api/admin/absent/setTime";

export default {
  props: {
    init: Boolean,
    isTeacher: Boolean,
    type: String
  },
  computed: mapGetters(["g_language"]),
  async created() {
    this.dataClassList = await class_list(this.dataAllList).then(
      r => r.data.data.data
    );
  },
  watch: {
    init: {
      immediate: true,
      handler() {
        if (this.init) this.getData();
      }
    }
  },
  components: {
    TimePicker: () => import("@/components/TimePicker"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  data() {
    return {
      confirmUpdate: false,
      dataUpdate: {},
      dataSchedule: [],
      dataClassList: [],
      loading: false,
      loadingConfirm: false,
      dataAllList: {
        sort: "class_master",
        order: "DESC",
        free: "",
        search: "",
        filter: {}
      },
      tableHeaders: [
        {
          text: i18n.t("set_time.table.day"),
          sortable: false,
          value: "day",
          width: 80
        },
        {
          text: i18n.t("set_time.table.lesson_hours"),
          sortable: false,
          value: "lesson_hours",
          align: "center"
        }
      ]
    };
  },
  methods: {
    isDialogUpdate(data) {
      this.confirmUpdate = true;
      this.dataUpdate = data;
    },
    isDayOff(data) {
      // 1 == day in, 0 == day off
      let indexSchedule = this.dataSchedule.indexOf(data);
      if (data.is_active == 0) {
        this.dataSchedule[indexSchedule].is_active = 1;
      } else {
        this.dataSchedule[indexSchedule].is_active = 0;
      }
    },
    subStr: data => data.substr(0, 4),
    postData() {
      this.loadingConfirm = true;
      let body = this.isTeacher
        ? { data: this.dataSchedule, type: this.type }
        : { data: this.dataSchedule };
      setScheduleBulk(body, this.isTeacher)
        .then(res => {
          if (res.data.code) {
            this.snackBar(true, this.$i18n.t("set_time.response.success_edit"));
          } else {
            this.snackBar(false, res.data.message);
          }
        })
        .catch(err => {
          console.error("postData()\n", err);
        })
        .finally(() => {
          this.confirmUpdate = false;
          this.loadingConfirm = false;
          this.getData();
        });
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    getData() {
      this.loading = true;
      this.dataSchedule = [];
      getScheduleGlobal(this.isTeacher, this.type)
        .then(res => {
          const r = res.data.data?.map(d =>
            Object.assign(d, { isDayOff: d.is_active == 0 ? false : true })
          );
          this.dataSchedule = r;
        })
        .catch(err => {
          console.error("getData()\n", err);
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
